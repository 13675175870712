.ant-modal-root .ant-form label {
  font-weight: normal;
}

.prompt-regular,
.prompt-regular .MuiButton-root {
  font-family: 'Prompt Regular' !important;
}

.anticon svg {
  vertical-align: baseline !important;
}

.bg-scan-products {
  background-color: #cee6fc;
  padding: 12px;
  border-radius: 6px;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 12px;
  row-gap: 16px;
}

@media (min-width: 768px) {
  .main-sidebar {
    z-index: auto !important;
  }
}

.main-header {
  z-index: auto !important;
}

.ant-btn-customize {
  /* float: right; */
  margin-bottom: 8px;
  border-radius: 4px;
  border-color: #40a9ff00;
}

.ant-btn-customize:hover {
  opacity: 0.6;
  color: #494949;
  border-color: #40a9ff00;
}

.ant-btn-customize:focus {
  color: #494949;
  border-color: #40a9ff00;
}

.ant-btn-primary:focus {
  opacity: 1 !important;
}

.ant-table-thead>tr>th {
  background: #525252;
  color: #fff;
}

.main-nested-table .ant-table-thead>tr>th {
  background: #525252;
  color: #fff;
}

.sub-nested-table .ant-table-thead>tr>th {
  background: #ececec;
  color: #000000;
}

.ant-table-small .ant-table-thead>tr>th {
  background-color: #525252;
  color: #fff !important;
  text-align: center !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}

/* .ant-table-tbody>tr.ant-table-row:hover>td {
  cursor: pointer !important;
  background: rgb(224, 239, 248)!important;
} */
.fa,
.fas {
  font-weight: 900;
  margin-right: 10px;
}

.head-table {
  background-color: #657acd !important;
}

.ant-tag-has-color {
  border-radius: 16px;
}

/* .sidebar {
  height: calc(100vh - (3.5rem + 1px));
} */
.text-link {
  margin-bottom: 0px;
  color: #1890ff;
  cursor: pointer;
}

.wait-approve {
  background-color: #fd7e14 !important;
  color: #fff;
}

.load-btn {
  background-color: #40a9ff !important;
  color: #fff;
}

.btn-submit {
  background-color: #007bff !important;
  color: #fff !important;
}

.btn-cancel {
  background-color: #d9d9d9 !important;
  color: #1f2d3d;
}

.tag-status {
  color: #ffffff;
  border: 1px solid;
  padding: 6px 12px;
  background-color: #ff963e;
  border-radius: 21px;
}

.text-link {
  margin-bottom: 0px;
  color: #1890ff;
  cursor: pointer;
}

.wait-approve {
  background-color: #fd7e14 !important;
  color: #fff;
}

.btn-submit {
  background-color: #007bff !important;
  color: #fff !important;
}

.btn-cancel {
  background-color: #d9d9d9 !important;
  color: #1f2d3d;
}

.text-remark {
  margin-bottom: 0px;
  color: #dc3545;
  cursor: pointer;
}

.text-remark-gray {
  font-size: 10px;
  margin-bottom: 0px;
  color: #929292;
  cursor: pointer;
}

.ant-menu {
  color: #fff;
  background: #343a40;
}

.ant-menu-sub.ant-menu-inline {
  background: #343a40;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #506983;
  color: #fff;
}

.ant-menu-submenu-selected {
  color: #ffffff;
}

.ant-picker {
  border-radius: 10px;
}

.timeline-status {
  position: absolute;
}

.border-zindex {
  z-index: 1;
  background-color: white;
  padding: 6px;
  text-align: center;
  font-weight: 600;
}

.line-div {
  width: 50%;
  margin-left: 45%;
  border-bottom: solid 2px #007bff;
}

.content-timeline {
  background-color: #0751a9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px 6px;
  color: #fff;
  text-align: center;
  margin: auto;
}

.MuiDialog-paperWidthMd {
  max-width: 30% !important;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: -1 !important;
}

.icon-schedule {
  font-size: 20px;
  margin-top: 15px;
  color: #666666;
}

.p-schedule {
  margin-bottom: 0px;
  color: #666666;
  font-size: 12px;
}

.schedule-16 {
  margin-bottom: 0px;
  color: #000;
  font-size: 16px;
}

.schedule-18 {
  margin-bottom: 0px;
  color: #000;
  font-size: 18px;
}

.schedule-20 {
  margin-bottom: 0px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.schedule-bg-gray {
  background: #d9d9d9;
}

.title-schedule-white-20 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.title-schedule-white-24 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.icon-schedule-black {
  font-size: 20px;
  color: #000;
}

.css-z0slbs-MuiTypography-root {
  display: none !important;
}

.css-49fffr {
  display: none !important;
}

.css-eez85g-MuiTypography-root {
  color: #fff;
  font-family: 'Prompt Regular' !important;
}

.css-llvhl4-MuiTypography-root {
  font-family: 'Prompt Regular' !important;
  font-size: 10px !important;
  color: #fff;
}

.css-yvbg89 {
  font-family: 'Prompt Regular' !important;
  font-size: 10px !important;
  color: #fff;
}

.css-126xj0f {
  z-index: -1 !important;
}

.css-fvtpii-MuiTypography-root {
  font-family: 'Prompt Regular' !important;
}

/*
.text-aaa{
  background-color: #000 !important;
} */
/* .css-fvtpii-MuiTypography-root{
  font-family: 'Prompt Regular' !important;
  font-size: 20px !important;
} */
.css-v1j55f {
  max-width: 100% !important;
}

.css-v1j55f>div {
  padding: 0 !important;
}

.title-modal {
  position: absolute;
  margin-top: -70px !important;
  margin-left: 58% !important;
}

.title-modal-rerecieve {
  position: absolute;
  margin-top: -70px !important;
  margin-left: 80% !important;
}

.title-modal-vivid {
  position: absolute;
  margin-top: -70px !important;
  margin-left: 70% !important;
}

.title-modal-rerecieve-vivid {
  position: absolute;
  margin-top: -70px !important;
  margin-left: 85% !important;
}

.parent {
  width: 100%;
  margin: auto;
  padding: 38px 26px;
}

.text-print {
  font-size: 22px;
}

.margin-row {
  margin-bottom: 8px;
}

.break {
  page-break-after: always;
}

/* table, th, td {
  border: 1px solid rgb(78, 78, 78);
  border-collapse: collapse;
  padding: 12px;
} */
/* .MuiButtonBase-root{
  font-family: 'Prompt Regular' !important;
  font-size: 10px !important;
  color: #fff !important;
} */

.menu-ufriend {
  line-height: 2em;
  height: 100%;
  padding: 0;
  margin: 0px;
  overflow: hidden scroll;
}

.ant-menu-submenu-popup>.ant-menu {
  background-color: #343a40;
}

.head-child-table {
  background-color: #d1e0f2 !important;
  color: #6b6c6f !important;
}

.head-table {
  background-color: #525252 !important;
}

.ant-table-tbody>tr>td {
  background-color: #fff;
}

.ant-table-tbody tr:nth-child(odd) td {
  background-color: #fff;
  /* background-color: #f8f9fa; */
}

.style-input {
  margin: 5px 0px;
  border-radius: 10px;
  text-align: right;
  border: 1px solid #d9d9d9;
  padding: 4px;
}

.style-input:hover {
  margin: 5px 0px;
  border-radius: 10px;
  text-align: right;
  border: 1px solid #40a9ff;
  padding: 4px;
}

.style-input:focus-visible {
  outline: none !important;
}

.ant-menu-submenu-popup>.ant-menu {
  background-color: #343a40;
}

.card-summary {
  margin-right: 12px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
}

/* 
body {
  font-family: Helvetica, Arial, Sans-Serif !important;
} */

.ant-upload-list-picture-card-container {
  width: 80%;
  height: 80%;
}

.play-sound-modal .ant-modal-footer {
  text-align: center;
}

.play-sound-modal .ant-modal-content {
  border-radius: 20px;
}

.play-sound-modal input[placeholder] {
  text-align: center;
}

.play-sound-modal .ant-input-number-handler-wrap {
  appearance: none;
  display: none;
}